<template>
  <div class="inline-block">
    <UButton
      :label="$t('gallery.button')"
      @click="isOpen = true"
    />
    <UModal
      v-model="isOpen"
      fullscreen
      :ui="{
        background: 'bg-[#252525]',
        container: 'flex text-center overflow-y-scroll',
        base: 'relative text-left flex flex-col',
      }"
    >
      <ModalCloseButton
        class="fixed top-0 right-0"
        @click="isOpen = false"
      />

      <div class="p-10 max-w-[1000px] mx-auto flex flex-col">
        <img
          width="1000"
          quality="50"
          loading="lazy"
          :alt="$t('gallery.img1.alt')"
          src="/img/gallery/turbokylling-1.webp"
          class="gallery-image"
        >
        <p class="text-white text-14 px-4 mt-2 mb-6 min-h-[20px] lg:text-20">
          {{ $t("gallery.img1.caption") }}
        </p>

        <img
          width="1000"
          quality="50"
          loading="lazy"
          :alt="$t('gallery.img2.alt')"
          src="/img/gallery/turbokylling-2.webp"
          class="gallery-image"
        >
        <p class="text-white text-14 px-4 mt-2 mb-6 min-h-[20px] lg:text-20">
          {{ $t("gallery.img2.caption") }}
        </p>

        <img
          width="1000"
          quality="50"
          loading="lazy"
          :alt="$t('gallery.img3.alt')"
          src="/img/gallery/turbokylling-3.webp"
          class="gallery-image"
        >
        <p class="text-white text-14 px-4 mt-2 mb-6 min-h-[20px] lg:text-20">
          {{ $t("gallery.img3.caption") }}
        </p>

        <img
          width="1000"
          quality="50"
          loading="lazy"
          :alt="$t('gallery.img4.alt')"
          src="/img/gallery/turbokylling-4.webp"
          class="gallery-image"
        >
        <p class="text-white text-14 px-4 mt-2 mb-6 min-h-[20px] lg:text-20">
          {{ $t("gallery.img4.caption") }}
        </p>
        <UButton
          class="uppercase mx-auto"
          :to="btnTo"
          :label="btnLabel"
        />
      </div>
    </UModal>
  </div>
</template>

<script setup lang="ts">
const isOpen = ref(false)

defineProps({
  btnTo: {
    type: String,
    default: undefined,
    required: true,
  },
  btnLabel: {
    type: String,
    default: undefined,
    required: true,
  },
})
</script>

<style>
.gallery-image {
  -webkit-mask-image: url(/img/masks/mask-gallery.svg);
  mask-image: url(/img/masks/mask-gallery.svg);
  -webkit-mask-size: 100% 100%;
  mask-size: 100% 100%;
  -webkit-mask-position: center;
  mask-position: center;
}
</style>

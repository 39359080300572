<template>
  <ModalBasic
    :btn-label="$t('modalCommitments.button')"
    @modal-open="track('viewed_commitments')"
  >
    <LayoutContentHeader class="!m-0">
      {{ $t("modalCommitments.title") }}
    </LayoutContentHeader>
    <div
      class="grid grid-cols-2 mt-4 py-6 xs:grid-cols-3 sm:grid-cols-4 md:grid-cols-5 gap-4 border-t border-t-gray-100"
    >
      <div
        v-for="(name, path) in commitments"
        :key="path"
        class="flex flex-col text-center"
      >
        <div class="flex-grow flex justify-center items-center">
          <img
            :src="`/img/companies/${path}`"
            :alt="name"
            class="w-4/5"
          >
        </div>

        <span class="inline-block mt-2 text-14 lg:text-18">
          {{ name }}
        </span>
      </div>
    </div>
  </ModalBasic>
</template>

<script lang="ts" setup>
const { track } = useTracker()
const commitments = {
  'rema-1000.svg': 'Rema 1000 (2020)',
  'narvesen.svg': 'Narvesen (2025)',
  '7-eleven.svg': '7-Eleven (2025)',
  'oda.svg': 'Oda (2026)',
  'scandic.svg': 'Scandic Hotels (2026)',
  'strawberry.svg': 'Strawberry (2026)',
  'circle-k.svg': 'Circle K (2023)',
  'max-burgers.svg': 'Max Burgers (2026)',
  'ssp.svg': 'SSP (2026)',
  'subway.svg': 'Subway (2026)',
}
</script>

<template>
  <div class="flex flex-start w-full flex-col relative">
    <LayoutHero
      :title="title || $t('frontpage.title')"
      :sub-title="sub_title || $t('frontpage.subTitle')"
    />

    <p class="text-lg text-center tracking-tight lg:text-18 mt-10">
      <ModalCommitments />
    </p>

    <LayoutSection :is-grid="false">
      <LayoutSectionHeader
        class="text-center lg:tracking-tight lg:max-w-5xl mx-auto"
        :text="$t('gallery.title')"
      />
      <LayoutSectionText :text="$t('gallery.text')" />
      <div
        class="mx-6 mt-7 grid grid-cols-2 gap-5 md:flex md:flex-wrap md:justify-center md:gap-x-9"
      >
        <img
          loading="lazy"
          width="300"
          height="200"
          :alt="$t('gallery.img1.alt')"
          src="/img/gallery/mini-turbokylling-1.webp"
          class="md:max-w-[300px] rounded-md"
        >
        <img
          loading="lazy"
          width="300"
          height="200"
          :alt="$t('gallery.img2.alt')"
          src="/img/gallery/mini-turbokylling-2.webp"
          class="md:max-w-[300px] rounded-md"
        >
        <img
          loading="lazy"
          width="300"
          height="200"
          :alt="$t('gallery.img3.alt')"
          src="/img/gallery/mini-turbokylling-3.webp"
          class="md:max-w-[300px] rounded-md"
        >
        <img
          loading="lazy"
          width="300"
          height="200"
          :alt="$t('gallery.img4.alt')"
          src="/img/gallery/mini-turbokylling-4.webp"
          class="md:max-w-[300px] rounded-md"
        >
      </div>

      <div class="flex justify-center mt-8">
        <ModalGallery
          :btn-label="$t('frontpage.ctaButton')"
          :btn-to="cta_button_to"
        />
      </div>
    </LayoutSection>

    <LayoutSection>
      <template #right>
        <img
          loading="lazy"
          width="953"
          height="395"
          :alt="$t('frontpage.section2.imgAlt')"
          src="/img/frontpage/turbokylling-solo.webp"
          class="w-auto h-auto mx-auto lg:mr-0 rounded-xl"
        >
      </template>
      <template #left>
        <NotationAll type="crossed-off">
          <LayoutSectionHeader :text="$t('frontpage.section2.title')" />
        </NotationAll>

        <LayoutSectionText>
          <i18n-t
            scope="global"
            keypath="frontpage.section2.text"
          >
            <template #modalButton>
              <ModalBasic
                :text="$t('frontpage.section2.modalText')"
                :btn-label="$t('frontpage.section2.modalButton')"
              />
            </template>
          </i18n-t>
        </LayoutSectionText>
      </template>
    </LayoutSection>

    <LayoutSection>
      <template #right>
        <LayoutSectionHeader :text="$t('frontpage.section3.title')" />

        <LayoutSectionText>
          <i18n-t
            scope="global"
            keypath="frontpage.section3.text"
          >
            <template #modalButton>
              <ModalInfoECC />
            </template>
          </i18n-t>
        </LayoutSectionText>
      </template>
      <template #left>
        <img
          loading="lazy"
          width="610"
          height="685"
          src="/img/frontpage/illustration-earth.webp"
          :alt="$t('frontpage.section3.imgAlt')"
          class="w-full h-auto mx-auto max-w-[500px] lg:mr-0"
        >
      </template>
    </LayoutSection>

    <LayoutSection>
      <template #right>
        <img
          loading="lazy"
          width="309"
          height="339"
          :alt="$t('frontpage.section4.imgAlt')"
          src="/img/frontpage/europa-map.svg"
          class="w-full h-auto m-auto"
        >
      </template>
      <template #left>
        <LayoutSectionHeader :text="$t('frontpage.section4.title')" />
        <LayoutSectionText :text="$t('frontpage.section4.text')" />
      </template>
    </LayoutSection>

    <div class="relative flex max-lg:flex-col lg:mt-56 justify-center items-center">
      <NotationAll
        type="circle"
        class="inline-block self-center"
      >
        <img
          loading="lazy"
          width="200"
          height="336.5"
          class="max-w-[200px] lg:m-0"
          alt="Liten gul kylling"
          src="/img/cutouts/liten-gul-kylling.webp"
        >
      </NotationAll>

      <div class="lg:max-w-xl px-4">
        <div class="mt-4 text-md lg:text-lg tracking-tight">
          <h2 class="font-semibold">
            {{ $t("frontpage.whyHelp.title") }}
          </h2>
          <ul class="pl-10 pr-10 mt-4 list-disc">
            <li>
              {{ $t("frontpage.whyHelp.reason1") }}
            </li>
            <li class="mt-2">
              {{ $t("frontpage.whyHelp.reason2") }}
            </li>
            <li class="mt-2">
              {{ $t("frontpage.whyHelp.reason3") }}
            </li>
            <li class="mt-2 font-bold">
              {{ $t("frontpage.whyHelp.reason4") }}
            </li>
          </ul>
        </div>
      </div>
      <div class="flex justify-center">
        <img
          width="107"
          height="186"
          loading="lazy"
          src="/img/arrow-l.svg"
          alt=""
          class="absolute left-0 lg:top-[80%] max-w-[70px] lg:left-[15%] xl:left-[25%]"
        >

        <img
          width="187"
          height="308"
          loading="lazy"
          src="/img/arrow-r.svg"
          alt=""
          class="absolute max-w-[110px] right-[2%] lg:right-[15%] xl:right-[25%] 2xl:right-[30%] top-[90%] xl:top-[55%]"
        >
      </div>
    </div>

    <div
      class="uppercase sticky bottom-6 w-full flex justify-center mt-[140px] z-20"
    >
      <UButton
        :label="cta_button || $t('frontpage.ctaButton')"
        :to="cta_button_to"
        size="xl"
        @click="track('sign_step_1')"
      />
    </div>

    <footer
      id="footer"
      class="mt-8"
    >
      <DecorationsFooter
        class="fill-green-600 translate-y-1"
      />
      <div class="bg-green-600 pt-0 pb-20">
        <div
          class="relative mt-0 flex flex-wrap justify-center items-center gap-y-2 text-12 text-white lg:divide-x lg:divide-white"
        >
          <div class="px-5">
            <a
              :href="`${branding.mainWebsite}?utm_source=cta_website&utm_medium=logo&utm_campaign=turbokylling`"
              target="_blank"
            >
              <LayoutLogo
                :lazy="true"
                :light-mode="true"
                :color="false"
              />
            </a>
          </div>
          <p class="px-5 py-2">
            <ModalBasic
              :text="$t('frontpage.imageCreditModal.text')"
              :btn-label="$t('frontpage.imageCreditModal.btn')"
            />
          </p>
          <p class="px-5 py-2">
            {{ $t('frontpage.orgNumber') }} {{ branding.orgNumber }}
          </p>
          <p class="px-5 py-2">
            {{ branding.address }}
          </p>
          <p class="px-5 py-2">
            <ModalPrivacyPolicy
              class="hover:underline cursor-pointer"
              :btn-label="$t('privacyPolicy.btnLabel')"
            />
          </p>
          <p class="px-5 py-2">
            <ModalSalesTerms
              class="hover:underline cursor-pointer"
              :btn-label="$t('salesTerms.btnLabel')"
            />
          </p>
          <p class="px-5 py-2">
            <a
              :href="`mailto:${branding.email}`"
              class="hover:underline cursor-pointer"
            >{{ branding.email }}</a>
          </p>
          <p class="px-5 py-2">
            <a
              :href="`tel:${branding.phone}`"
              class="hover:underline cursor-pointer"
            >{{ branding.phone }}</a>
          </p>
          <p class="px-5 py-2">
            <UButton
              :label="$t('btnEditCookies')"
              variant="link"
              size="none"
              @click="cookies.showCookieBanner = true"
            />
          </p>
        </div>

        <p class="relative mt-6 px-5 text-white max-w-[700px] mx-auto text-12 text-center">
          {{ $t("frontpage.footerImageExplaination") }}
        </p>
      </div>
    </footer>
  </div>
</template>

<script setup lang="ts">
const { branding } = getData()
const cookies = useCookieStore()
const { track } = useTracker()
const cta_button_to = ref<string>('/claims')

useHead({
  link: [
    { rel: 'prefetch', href: '/video/claim-1.mp4', as: 'video' },
    { rel: 'prefetch', href: '/video/claim-2.mp4', as: 'video' },
    { rel: 'prefetch', href: '/video/claim-3.mp4', as: 'video' },
    // { rel: "prefetch", href: "/img/frontpage/footer-bg.webp", as: "image" },
  ],
})

const route = useRoute()
const { title, sub_title, cta_button } = route.query as { [key: string]: string | undefined }

useStoreUTM(true)

onMounted(() => {
  if ('nextPage' in route.query) cta_button_to.value = route.query.nextPage as string
})
</script>
